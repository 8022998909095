import WebMailboxApiRoutes from '@config/Routes/WebMailBoxApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken, reset) => {
  return new ServiceClientFactory({
    baseUrl: WebMailboxApiRoutes.getBaseUrl(),
    accessToken,
    reset,
  }).create();
};

const MailBoxService = {
  createMailBoxAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.createMailBoxPath(),
      parameters,
    });
  },

  createMailBoxChangeRequestAPI: (paramsList) => {
    const { accessToken, reset, pageNumber } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getMailBoxChangeRequestPath({ pageNumber }),
    });
  },

  createMailBoxSyncAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.createMailBoxSyncPath(),
      parameters,
    });
  },

  createMailBoxSyncRequestAPI: (paramsList) => {
    const { accessToken, reset, pageNumber } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getMailBoxSyncPath({ pageNumber }),
    });
  },

  getExcludedMailboxesAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;
    const urlParams = { pageNumber };
    if (pageSize) {
      urlParams.pageSize = pageSize;
    }
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getExcludedMailboxesPath(urlParams),
    });
  },

  createExcludedMailboxesAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.createExcludedMailboxesPath(),
      parameters,
    });
  },

  getAutoDiscoveryAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;
    const urlParams = { pageNumber };
    if (pageSize) {
      urlParams.pageSize = pageSize;
    }
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getAutoDiscoveryPath(urlParams),
    });
  },

  createAutoDiscoveryAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.createAutoDiscoveryPath(),
      parameters,
    });
  },

  getDataIngestionAPI: (paramsList) => {
    const { accessToken, reset, searchById, pageNumber, pageSize, filter } = paramsList;
    const urlParams = { pageNumber };
    if (pageSize) {
      urlParams.pageSize = pageSize;
    }
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getDataIngestionPath(urlParams),
      parameters: {
        'search-by-id': Number(searchById),
        filters: filter,
      },
    });
  },

  getDetailsOfDataIngestionAPI: (paramsList) => {
    const { accessToken, reset, ingestionID, pageNumber, pageSize, filterName, selectedValue } =
      paramsList;
    const urlParams = {};
    if (selectedValue && selectedValue !== 'All') {
      urlParams.filterName = filterName;
      urlParams.filterValue = selectedValue;
    }
    urlParams.pageNumber = pageNumber;
    urlParams.pageSize = pageSize;
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getDetailsOfDataIngestionPath(urlParams, `/${ingestionID}`),
    });
  },

  createDataIngestionAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postIngestionRequest({
      url: WebMailboxApiRoutes.createDataIngestionPath(),
      parameters,
    });
  },

  getFeatureFlagAPI: (paramsList) => {
    const { accessToken, reset } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getFeatureFlagPath(),
    });
  },

  getMailboxBulkPurgeAPI: (paramsList) => {
    const { accessToken, reset, pageSize, pageNumber } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getMailboxBulkPurgePath({ pageSize, pageNumber }),
    });
  },

  createBulkPurgeRequestAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.createBulkPurgeRequestPath(),
      parameters,
    });
  },

  getMailboxChangeDetailAPI: (paramsList) => {
    const { accessToken, reset, id, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getMailBoxChangeRequestPath(
        { pageSize, pageNumber },
        `/${id}/items`
      ),
    });
  },

  getMailboxChangeStatusAPI: (paramsList) => {
    const { accessToken, reset, id } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getMailBoxChangeRequestPath({}, `/${id}`),
    });
  },

  getMailboxBulkPurgeListAPI: (paramsList) => {
    const { accessToken, reset, organizationID, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getMailboxBulkPurgeListPath(
        { pageSize, pageNumber },
        `/${organizationID}/items`
      ),
    });
  },

  getBulkPurgeMailStatusAPI: (paramsList) => {
    const { accessToken, reset, organizationID } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getBulkPurgeMailStatusPath({}, `/${organizationID}`),
    });
  },

  getDomainBackupsAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getDomainBackupsListPath({ pageNumber, pageSize }),
    });
  },

  createDomainBackupsAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.createDomainBackupsPath(),
      parameters,
    });
  },

  getTransferSubresellerAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getTransferSubresellerListPath({ pageNumber, pageSize }),
    });
  },

  getTransferSubscriptionAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getTransferSubscriptionListPath({ pageNumber, pageSize }),
    });
  },

  getTransferSubscriptionInitiateAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getTransferSubscriptionInitiateListPath({ pageNumber, pageSize }),
    });
  },

  createTransferSubscriptionInitiateAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.createTransferSubscriptionInitiateListPath(),
      parameters,
    });
  },

  getIngestionReportListAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize, ingestionID } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getIngestionReportListPath(
        { pageNumber, pageSize },
        `/${ingestionID}`
      ),
    });
  },

  getIngestionReportListItemAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize, ingestionID } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getIngestionReportListItemPath(
        { pageNumber, pageSize },
        `/${ingestionID}`
      ),
    });
  },

  createDataIngestionJobAPI: (paramsList) => {
    const { accessToken, reset, parameters, ingestionJobID } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.createDataIngestionJobPath({}, `/${ingestionJobID}`),
      parameters,
    });
  },

  reRunDataIngestionListItemAPI: (paramsList) => {
    const { accessToken, reset, ingestionJobID, selectedItems } = paramsList;
    const parameters = {
      'ingestion-job-id': ingestionJobID,
      'selected-items': selectedItems?.map((strNumber) => parseInt(strNumber, 10)),
    };

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.reRunDataIngestionListItemPath(),
      parameters,
    });
  },

  getIngestionFailedEmlAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize, ingestionID } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getIngestionFailedEmlItemPath(
        { pageNumber, pageSize },
        `/${ingestionID}`
      ),
    });
  },

  getSeatUsageReportsAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize, orgOwnerId, protocol, credentialId } =
      paramsList;
    const parameters = {
      'org-owner-id': Number(orgOwnerId),
      protocol: protocol && protocol > 0 ? Number(protocol) : null,
      'credential-id': credentialId ? Number(credentialId) : null,
    };

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getSeatUsageReportsPath({ pageNumber, pageSize }),
      parameters,
    });
  },

  getSeatUsageReportsSummaryAPI: (paramsList) => {
    const { accessToken, reset, orgOwnerId } = paramsList;
    const parameters = { 'org-owner-id': Number(orgOwnerId) };

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getSeatUsageReportsSummaryPath(),
      parameters,
    });
  },

  exportSeatUsageReportAPI: (paramsList) => {
    const { accessToken, reset, orgOwnerId, protocol, credentialId, pageNumber, pageSize } =
      paramsList;
    const parameters = {
      'org-owner-id': Number(orgOwnerId),
      protocol: protocol && protocol > 0 ? Number(protocol) : null,
      'credential-id': credentialId ? Number(credentialId) : null,
    };

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.exportSeatUsageReportPath({ pageNumber, pageSize }),
      parameters,
    });
  },

  removeDataIngestionItemsAPI: (paramsList) => {
    const { accessToken, reset, ingestionJobID, selectedItems } = paramsList;
    const parameters = {
      'ingestion-job-id': ingestionJobID,
      'selected-items': selectedItems?.map((strNumber) => parseInt(strNumber, 10)),
    };

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.removeDataIngestionItemsPath(),
      parameters,
    });
  },

  deleteDataIngestionJobAPI: (paramsList) => {
    const { accessToken, reset, selectedJobs } = paramsList;
    const parameters = {
      'selected-jobs': selectedJobs?.map((strNumber) => parseInt(strNumber, 10)),
    };
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.deleteDataIngestionJobPath(),
      parameters,
    });
  },

  submitPurgeExternalAccountAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.submitPurgeExternalAccountPath(),
      parameters,
    });
  },

  getPurgeExternalAccountAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getPurgeExternalAccountPath({ pageNumber, pageSize }),
    });
  },

  submitPurgeSharedDriveAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.submitPurgeSharedDrivePath(),
      parameters,
    });
  },

  getPurgeSharedDriveAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getPurgeSharedDrivePath({ pageNumber, pageSize }),
    });
  },

  getTransferPartnerAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getTransferPartnerPath({ pageNumber, pageSize }),
    });
  },

  submitTransferPartnerAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.submitTransferPartnerPath(),
      parameters,
    });
  },

  validateTransferPartnerAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.validateTransferPartnerPath(),
      parameters,
    });
  },

  getPartnersListAPI: (paramsList) => {
    const {
      accessToken,
      reset,
      reseller,
      pageNumber,
      pageSize,
      fullTextSearch,
      searchById,
      partnerType,
      status,
      integrationType,
      orderBy,
      salesOwner,
      partnerName,
    } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getPartnersListPath({
        pageNumber,
        pageSize,
        reseller,
      }),
      parameters: {
        'full-text-search': fullTextSearch,
        'search-by-id': Number(searchById),
        'partner-type': partnerType,
        status,
        'order-by': orderBy,
        'integration-type': integrationType,
        filters: [],
        'sales-owner': salesOwner,
        'partner-name': partnerName,
      },
    });
  },
  getOrganizationsListAPI: (paramsList) => {
    const {
      accessToken,
      reset,
      pageNumber,
      pageSize,
      fullTextSearch,
      searchById,
      reseller,
      filter,
      orderBy,
    } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getOrganizationsListPath({
        pageNumber,
        pageSize,
        reseller,
      }),
      parameters: {
        'full-text-search': fullTextSearch,
        'search-by-id': Number(searchById),
        filters: filter,
        'order-by': orderBy,
      },
    });
  },

  exportOrganizationsAPI: (paramsList) => {
    const { accessToken, reset, fullTextSearch, searchById, reseller, filter, orderBy } =
      paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.exportOrganizationPath({ reseller }),
      parameters: {
        'full-text-search': fullTextSearch,
        'search-by-id': Number(searchById),
        filters: filter,
        'order-by': orderBy,
      },
    });
  },

  getOrganizationInformationAPI: (paramsList) => {
    const { accessToken, reset, reseller } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getOrganizationInformationPath({
        ownerId: reseller,
      }),
    });
  },

  getM365TenantAPI: (paramsList) => {
    const {
      accessToken,
      reset,
      pageNumber,
      pageSize,
      fullTextSearch,
      searchById,
      organizationOwnerId,
      partnerID,
      filter,
      orderBy,
    } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getM365TenantPath({
        pageNumber,
        pageSize,
        organizationOwnerId,
        partnerID,
      }),
      parameters: {
        'full-text-search': fullTextSearch,
        'search-by-id': Number(searchById),
        filters: filter,
        'order-by': orderBy,
      },
    });
  },

  exportM365TenantAPI: (paramsList) => {
    const {
      accessToken,
      reset,
      fullTextSearch,
      searchById,
      organizationOwnerId,
      partnerID,
      filter,
      orderBy,
    } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.exportM365TenantPath({
        organizationOwnerId,
        partnerID,
      }),
      parameters: {
        'full-text-search': fullTextSearch,
        'search-by-id': Number(searchById),
        filters: filter,
        'order-by': orderBy,
      },
    });
  },

  getM365TenantDetailsAPI: (paramsList) => {
    const { accessToken, reset, m365TenantID } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getM365TenantDetailsPath({ credentialId: m365TenantID }),
    });
  },

  getM365UsersTenantAPI: (paramsList) => {
    const {
      accessToken,
      reset,
      credentialId,
      pageNumber,
      pageSize,
      fullTextSearch,
      filter,
      orderBy,
    } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getM365UsersTenantPath({ pageNumber, pageSize, credentialId }),
      parameters: {
        'full-text-search': fullTextSearch,
        filters: filter,
        'order-by': orderBy,
      },
    });
  },

  getM365SitesTenantAPI: (paramsList) => {
    const {
      accessToken,
      reset,
      credentialId,
      pageNumber,
      pageSize,
      fullTextSearch,
      filter,
      orderBy,
    } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getM365SitesTenantPath({ pageNumber, pageSize, credentialId }),
      parameters: {
        'full-text-search': fullTextSearch,
        filters: filter,
        'order-by': orderBy,
      },
    });
  },

  getGWSTenantAPI: (paramsList) => {
    const {
      accessToken,
      reset,
      organizationOwnerId,
      partnerID,
      pageNumber,
      pageSize,
      fullTextSearch,
      searchById,
      filter,
      orderBy,
    } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getGWSTenantPath({
        pageNumber,
        pageSize,
        organizationOwnerId,
        partnerID,
      }),
      parameters: {
        'full-text-search': fullTextSearch,
        'search-by-id': Number(searchById),
        filters: filter,
        'order-by': orderBy,
      },
    });
  },

  getGWSTenantDetailsAPI: (paramsList) => {
    const { accessToken, reset, gwsTenantID } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getGWSTenantDetailsPath({ credentialId: gwsTenantID }),
    });
  },

  getHEXTenantDetailsAPI: (paramsList) => {
    const { accessToken, reset, hexTenantId } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getHEXTenantDetailsPath({ credentialId: hexTenantId }),
    });
  },

  getHEXTenantAPI: (paramsList) => {
    const {
      accessToken,
      reset,
      organizationOwnerId,
      pageNumber,
      pageSize,
      fullTextSearch,
      searchById,
      filter,
      orderBy,
    } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getHEXTenantPath({
        pageNumber,
        pageSize,
        organizationOwnerId,
      }),
      parameters: {
        'full-text-search': fullTextSearch,
        'search-by-id': Number(searchById),
        filters: filter,
        'order-by': orderBy,
      },
    });
  },

  getPlansListAPI: (paramsList) => {
    const {
      accessToken,
      reset,
      pageNumber,
      pageSize,
      fullTextSearch,
      searchById,
      orderBy,
      reseller,
      filter,
    } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getPlansListPath({
        pageNumber,
        pageSize,
        reseller,
      }),
      parameters: {
        'full-text-search': fullTextSearch,
        'search-by-id': Number(searchById),
        'order-by': orderBy,
        filters: filter,
      },
    });
  },

  submitSeatCountChangeAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.submitSeatCountChangePath(),
      parameters,
    });
  },

  getSeatCountChangeAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getSeatCountChangePath({ pageNumber, pageSize }),
    });
  },

  getUsersListAPI: (paramsList) => {
    const {
      accessToken,
      reset,
      pageNumber,
      pageSize,
      fullTextSearch,
      searchById,
      orderBy,
      reseller,
      filter,
    } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getUsersListPath({
        pageNumber,
        pageSize,
        reseller,
      }),
      parameters: {
        'full-text-search': fullTextSearch,
        'search-by-id': Number(searchById),
        'order-by': orderBy,
        filters: filter,
      },
    });
  },

  getOrganizationUsersListAPI: (paramsList) => {
    const {
      accessToken,
      reset,
      pageNumber,
      pageSize,
      fullTextSearch,
      searchById,
      orderBy,
      filter,
    } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getOrganizationUsersListPath({
        pageNumber,
        pageSize,
      }),
      parameters: {
        'full-text-search': fullTextSearch,
        'search-by-id': Number(searchById),
        'order-by': orderBy,
        filters: filter,
      },
    });
  },

  getSelectedResellerConfigsAPI: (paramsList) => {
    const { accessToken, reset, reseller } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getSelectedResellerConfigsPath({
        reseller,
      }),
    });
  },

  getResellerNotificationsConfigAPI: (paramsList) => {
    const { accessToken, reset, reseller } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getResellerNotificationsConfigPath({
        reseller,
      }),
    });
  },
  getMonthlyBackupReportsAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize, resellerId } = paramsList;
    const parameters = { 'reseller-id': Number(resellerId) };

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.getMonthlyBackupReportsPath({ pageNumber, pageSize }),
      parameters,
    });
  },
  exportMonthlyBackupReportAPI: (paramsList) => {
    const { accessToken, reset, resellerId, pageNumber, pageSize } = paramsList;
    const parameters = { 'reseller-id': Number(resellerId) };

    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.exportMonthlyBackupReportPath({ pageNumber, pageSize }),
      parameters,
    });
  },

  getTenantAuthErrorsAPI: (paramsList) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;
    const urlParams = { pageNumber };
    if (pageSize) {
      urlParams.pageSize = pageSize;
    }
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getTenantAuthErrorsPath(urlParams),
    });
  },

  createTenantAuthErrorsAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.createTenantAuthErrorsPath(),
      parameters,
    });
  },

  getIngestionsQueuedAPI: (paramsList) => {
    const { accessToken, reset } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getIngestionsQueuedPath(),
    });
  },
  getIngestionsInProgressAPI: (paramsList) => {
    const { accessToken, reset } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getIngestionsInProgressPath(),
    });
  },
  getPartnersFilterListAPI: (paramsList) => {
    const { accessToken, reset, searchTerm, isOnlyParent, parentId } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getPartnersFilterListPath({
        searchTerm,
        isOnlyParent,
        parentId,
      }),
    });
  },

  getOrganizationFilterListAPI: (paramsList) => {
    const { accessToken, reset, searchTerm, partnerId } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getOrganizationFilterListPath({
        searchTerm,
        partnerId,
      }),
    });
  },

  getParentFilterListAPI: (paramsList) => {
    const { accessToken, reset, searchTerm } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getParentFilterListPath({
        searchTerm,
      }),
    });
  },

  getSwitchCredentialRequestAPI: (paramsList) => {
    const { accessToken, reset, pageSize, pageNumber } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getSwitchCredentialListPath({ pageSize, pageNumber }),
    });
  },

  getSwitchCredentialStatusAPI: (paramsList) => {
    const { accessToken, reset, searchTerm, id } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getSwitchCredentialStatusPath({
        searchTerm,
        id,
      }),
    });
  },

  getSwitchCredentialItemsAPI: (paramsList) => {
    const { accessToken, reset, searchTerm, id, pageSize, pageNumber } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getSwitchCredentialItemsPath({
        searchTerm,
        id,
        pageSize,
        pageNumber,
      }),
    });
  },

  createSwitchCredentialAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.createSwitchCredentialPath(),
      parameters,
    });
  },

  bulkCreateOrganizationApi: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.bulkCreateOrganizationPath(),
      parameters,
    });
  },

  getBulkCreateOrganizationItemsApi: (paramsList) => {
    const { accessToken, reset, id, pageNumber, pageSize } = paramsList;
    const urlParams = { id, pageNumber };
    if (pageSize) {
      urlParams.pageSize = pageSize;
    }
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getBulkCreateOrganizationItemsPath(urlParams),
    });
  },

  getBulkCreateOrganizationTemplateApi: (paramsList) => {
    const { accessToken, reset } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getBulkCreateOrganizationTemplatePath(),
    });
  },

  bulkCreateSubResellerApi: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebMailboxApiRoutes.bulkCreateSubResellerPath(),
      parameters,
    });
  },

  getBulkCreateSubResellerItemsApi: (paramsList) => {
    const { accessToken, reset, id, pageNumber, pageSize } = paramsList;
    const urlParams = { id, pageNumber };
    if (pageSize) {
      urlParams.pageSize = pageSize;
    }
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getBulkCreateSubResellerItemsPath(urlParams),
    });
  },

  getBulkCreateSubResellerTemplateApi: (paramsList) => {
    const { accessToken, reset } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebMailboxApiRoutes.getBulkCreateSubResellerTemplatePath(),
    });
  },
};

export default MailBoxService;
